import { useState, useContext } from 'react'
import WorkOrderContext from '../context/WorkOrderContext'

function FormSection() {

  const [siteName, setSiteName] = useState('')
  const [jobTaskDescription, setJobTaskDescription] = useState('')
  const [jobTaskHours, setJobTaskHours] = useState(null)
  const [jobTaskList, setJobTaskList] = useState([])



  const { workOrders, setWorkOrders } = useContext(WorkOrderContext)

  const handleSiteNameChange = (e) => {
    setSiteName(e.target.value)
    // if (siteName.length >= 3) {
    //   setIsDisabled(false)
    // }
  }

  const handleJobTaskChange = (e) => {
    if (e.target.name === 'jobTaskDescription') {
      setJobTaskDescription(e.target.value)
    } else if (e.target.name === 'jobTaskHours') {
      setJobTaskHours(e.target.value)
    }

  }


  const handleJobTaskSubmit = (e) => {
    e.preventDefault()
    setJobTaskList([...jobTaskList, { taskDesc: jobTaskDescription, taskHours: jobTaskHours }])
    setJobTaskDescription('')
    setJobTaskHours('')
  }


  const handleWorkOrderSubmit = (e) => {
    e.preventDefault()
    setWorkOrders([...workOrders, { workOrderName: siteName, workOrderItems: jobTaskList }])
    setSiteName('')
    setJobTaskList([])
    setJobTaskHours('')
  }





  return (
    <>
      <div className="card mt-4">
        <div className="card-header">
          Work Order Form
        </div>
        <div className="card-body">

          <div className="mb-3 border-bottom pb-4">
            <label className="form-label">Site Name</label>
            {/* <select class="form-select" aria-label="Default select example">
              <option selected>Open this select menu</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select> */}
            <input type="text" className="form-control" name="siteName" onChange={handleSiteNameChange} value={siteName} />
          </div>

          {/* start task form */}
          <div className="mb-3 border-bottom pb-2">
            <label className="form-label">Job Tasks</label>
            <form className="row g-3">
              <div className="col-8">
                <input type="text" className="form-control" placeholder="Item Description" name="jobTaskDescription" value={jobTaskDescription} onChange={handleJobTaskChange} required />
              </div>
              <div className="col-2">
                <input type="number" step="0.25" className="form-control" placeholder="Hours" name="jobTaskHours" value={jobTaskHours} onChange={handleJobTaskChange} required />
              </div>

              <div className="col-2 d-grid">
                <button type="submit" className="btn btn-secondary mb-3" onClick={handleJobTaskSubmit}>Add</button>
              </div>
            </form>
          </div>
          {/* end task form */}

          <ul className="list-group">
            {jobTaskList.map((task, index) => (
              <li key={index} className="list-group-item">{task.taskDesc} - ({task.taskHours} hrs)</li>
            ))}
          </ul>

        </div>
        <div className="col-12"><p className="text-center text-muted mt-0" style={{ fontSize: '10px' }}>(Site Name and 1+ Job Task Required)</p></div>
        <div className="col-12 d-grid bg-light px-4 border-top">
          <button type="submit" className="btn btn-primary my-4 " onClick={handleWorkOrderSubmit} disabled={siteName.length >= 3 ? jobTaskList.length >= 1 ? false : true : true}>Add Work Order</button>
        </div>
      </div>

    </>
  )
}
export default FormSection