import { useContext } from 'react'
import WorkOrderContext from '../context/WorkOrderContext'


function WorkOrderList() {

  const { workOrders } = useContext(WorkOrderContext)

  return (
    <>
      <div className="container">
        <h3 className="mt-4">WorkOrderList</h3>
        <p>(data stored in state... disregard this section)</p>
        <div class="list-group">
          {workOrders.map((item, index) => (
            <button type="button" class="list-group-item list-group-item-action" key={index}><strong>{item.workOrderName}</strong>
              <ol>
                {item.workOrderItems.map((hmm, index2) => (
                  <li key={index2}>{hmm.taskDesc} ({hmm.taskHours} hrs)</li>
                ))}
              </ol>
            </button>
          ))}
        </div>
      </div>






    </>
  )
}
export default WorkOrderList