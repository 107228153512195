import { useState, createContext } from 'react'

const WorkOrderContext = createContext()

export const WorkOrderProvider = ({ children }) => {

  const [workOrders, setWorkOrders] = useState([])

  return (
    <WorkOrderContext.Provider value={{
      workOrders,
      setWorkOrders,
    }}>
      {children}
    </WorkOrderContext.Provider>
  )
}

export default WorkOrderContext