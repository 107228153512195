import FormSection from "./components/FormSection";
import WorkOrderList from "./components/WorkOrderList";
import { WorkOrderProvider } from './context/WorkOrderContext'


function App() {
  return (
    <WorkOrderProvider>
      <div className="container-fluid page">
        <div className="row">
          <div className="col-3 sidebar"><WorkOrderList /></div>
          <div className="col-9 justify-content-center"><FormSection /></div>
        </div>
      </div>
    </WorkOrderProvider>
  );
}

export default App;
